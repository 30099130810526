import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import img from "../static/slider_images/1.jpg";
import a1 from "../images/industrial/A1 - Malmedie Drum Coupling TTX.jpg";
import a2 from "../images/industrial/A2 - Malmedie Gear Coupling LX.jpg";
import a3 from "../images/industrial/A3 - Malmedie Safety Coupling SE.jpg";
import b1 from "../images/industrial/B1 - Malmedie Mining Solution - Gear Coupling SE-GLX.png";
import b2 from "../images/industrial/B2 - Malmedie Mining Solution.png";
import c1 from "../images/industrial/C1 - Malmedie  Mining Solution - Safety Coupling - SE-E.png";
import c2 from "../images/industrial/C2 - Malmedie  Mining Solution.png";

import { MainLayout } from "../layouts/MainLayout";

// markup
const MaterialHandlingEquipment = () => {
  return (
    <MainLayout>
      <section className="section">
        <main className="container">
          <div className="page-content">
            <div className="columns">
              <div className="column">
                <div className="page-title">
                  <h1>INDUSTRIAL EQUIPMENT </h1>
                  {/* <figure className="image is-96x96">
                    <img src={img} alt="Factory" />
                  </figure> */}
                </div>
                <p className="page-description">
                  We understand your diverse requirements and offer superior
                  solutions designed to deliver accurate, consistent
                  performance. We are the preferred supplier of parts, equipment
                  and security systems for industrial plants, especially in
                  mining, cement, agriculture industries.
                </p>
                <figure className="service-image">
                  <img src={img} alt="mining-image" />
                </figure>
                <section className="products">
                  {/* <h3 className="title">Products</h3> */}
                  <h5>Product Images - Industrial Couplings</h5>
                  <ul>
                    <li>
                      <figure>
                        <img src={a1} alt={a1} />
                        <p>Malmedie Drum Coupling TTX</p>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={a2} alt={a2} />
                        <p>Malmedie Gear Coupling LX</p>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={a3} alt={a3} />
                        <p>Malmedie Safety Coupling SE</p>
                      </figure>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <figure>
                        <img src={b2} alt={b2} />
                        <p>Malmedie Mining Solution</p>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={b1} alt={b1} />
                        <p>Malmedie Mining Solution - Gear Coupling SE-GLX</p>
                      </figure>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <figure>
                        <img src={c2} alt={c2} />
                        <p>Malmedie Mining Solution</p>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={c1} alt={c1} />
                        <p>Malmedie Mining Solution - Safety Coupling - SE-E</p>
                      </figure>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column">
                <section className="page-list">
                  <h3 className="title">Products</h3>
                  <ul>
                    <li>
                      <p className="list-title">
                        <span>Couplings</span>
                        Our range of accurately designed couplings transmits
                        torque for niche applications in mining and related
                        industries.
                      </p>
                      <p className="leading-brand">Leading Brands Handled </p>
                      <span className="brands"> Siemens Products,</span>
                      <span className="brands"> Malmedie Drum Couplings,</span>
                      <span className="brands">Malmedie Gear Couplings,</span>
                      <span className="brands">Malmedie Safety Couplings,</span>
                      <span className="brands">Flender Couplings</span>
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Sensors</span>
                        We represent approved brands in sensors and measuring
                        instruments, which are well-suited for underground
                        mining, high temperatures and corrosive applications.
                      </p>
                      <p className="leading-brand">Leading Brands Handled</p>
                      <span className="brands">IFM,</span>
                      <span className="brands">Pepperl+ Fuchs,</span>
                      <span className="brands">Turck,</span>
                      <span className="brands">Banner Engineering,</span>
                      <span className="brands">Baumer</span>
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Hydraulics</span>A range of fluid conditioning
                        systems, hydraulic equipment and accumulators are
                        available, to meet your demanding requirements and
                        performance measures.
                      </p>
                      <p className="leading-brand">Leading Brands Handled</p>
                      <span className="brands">Hydac</span>
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Electronics</span>
                        The best-in-class electronics systems and devices enable
                        faster automation in the manufacturing sector, and
                        allows for faster, flexible solutions and services.
                      </p>
                      <p className="leading-brand">Leading Brands Handled </p>
                      <span className="brands">Siemens</span>
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Electrical Drives & Cables</span>
                        We offer drive technologies and automation solutions of
                        leading brands in electrical drives. The systems can
                        handle capacities upto 100 tonnes.
                      </p>
                      <p className="leading-brand">Leading Brands Handled </p>
                      <span className="brands">Siemens Drives,</span>
                      <span className="brands">SEW Usocome Drives,</span>
                      <span className="brands">Woelfert Drives,</span>
                      <span className="brands">Tratos Cables,</span>
                      <span className="brands">Prysmian Cables,</span>
                      <span className="brands">Huebner</span>
                    </li>

                    {/* <li>
                      <p className="list-title">
                        <span>Hydrostruts</span>
                        For civil, dual-use purposes{" "}
                      </p>
                      <p className="leading-brand">Leading Brands Handled </p>
                      <span className="brands">ITT Motion Technologies</span>
                    </li> */}
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </main>
      </section>
    </MainLayout>
  );
};

export default MaterialHandlingEquipment;
